<template>
  <li class="form_li">
    <div class="form_ttl essen">쿠폰번호(PIN) 등록(최대 5개)</div>
    <div class="form_in">
      <label class="input_file">
        <button class="txtbutton" @click="fnModalAlertPinNumberAdd">
          추가
        </button>
      </label>
      <div v-if="computedPinList.length > 0">
        <div
          class="inline"
          style="margin-top: 0.5rem"
          v-for="(item, index) in computedPinList"
          :key="index"
        >
          <label @click="fnModalAlertPinNumberAdd(index, item)" class="input">
            <input
              class="clickable"
              :value="item.pin"
              :class="{ disabled: item.memberSeq && item.memberSeq != 0 }"
              disabled
            />
          </label>
          <button
            class="btn_delete"
            v-if="!item.memberSeq || item.memberSeq == 0"
            @click="fnDeletePin(index)"
          >
            삭제
          </button>
        </div>
      </div>
      <div class="hint">
        동일한 제품만 복수 등록이 가능합니다.
      </div>
    </div>
  </li>
</template>

<script>
import { getCurrentInstance, reactive, toRefs, computed } from 'vue'
import { fnModalAlert } from '@/components/common/modal/alert'

const componentState = props => {
  const { proxy } = getCurrentInstance()
  const state = reactive({
    pinList: [],
    computedPinList: computed(() => {
      if (props.editPinList) {
        state.pinList = props.editPinList
        return state.pinList
      } else {
        return state.pinList
      }
    })
  })

  const fnModalAlertPinNumberAdd = async (index, item) => {
    if (item && item.memberSeq && item.memberSeq != 0) return
    let data = {}
    if (item) {
      data = { ...item }
      data.isEdit = true
    }
    if (proxy.$Util.isEmpty(item) && state.pinList.length >= props.maxCount)
      return alert('최대 5개 까지 등록 가능합니다.')
    const payload = {
      component: proxy.$modalAlertNames.PIN_NUMBER_ADD,
      data,
      callback: aPin => {
        const copiedPinList = [...state.pinList]
        if (item) {
          copiedPinList.splice(index, 1, aPin)
          state.pinList = copiedPinList
        } else {
          state.pinList = [...state.pinList, aPin]
        }
        proxy.$emit('update', state.pinList)
      }
    }

    await fnModalAlert(payload)
  }

  const fnDeletePin = index => {
    if (
      !confirm(
        '쿠폰번호 삭제 시 “추가”버튼을 통해 재등록을 진행해야 합니다.삭제 하시겠습니까?'
      )
    )
      return
    state.pinList.splice(index, 1)
    proxy.$emit('update', state.pinList)
  }

  return {
    ...toRefs(state),
    fnDeletePin,
    fnModalAlertPinNumberAdd
  }
}

export default {
  name: 'pin-number-add-list',
  props: {
    maxCount: {
      type: [Number],
      default: () => 5
    },
    editPinList: {
      type: [Array],
      default: undefined
    }
  },
  setup(props) {
    const state = componentState(props)
    return { ...state }
  }
}
</script>

<style scoped></style>
