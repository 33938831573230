import { computed, reactive, toRefs, getCurrentInstance } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { fnModalAlert } from '@/components/common/modal/alert'

export const componentState = props => {
  const { proxy } = getCurrentInstance()
  const route = useRoute()
  const router = useRouter()
  const { getters } = useStore()
  const state = reactive({
    userData: computed(() => getters['user/getData']),
    form: {},
    marketInfo: {},
    sellerFee: 0,
    computedSellerFee: computed(() =>
      Math.floor(state.sellerFee * state.marketInfo.price)
    ),
    expectedTotalAmount: computed(
      () => Number(state.marketInfo.price) - state.computedSellerFee
    ),
    fileList: [],
    params: route.query,
    categoryList: computed(() => getters['nav/getCategoryList']),
    category: [],
    files: [],
    deleteFiles: [],
    itemList: [],
    categoryNm: computed(() => {
      if (state.marketInfo.categoryId) {
        return proxy.$ConstCode.getCategoryById(state.marketInfo.categoryId)
          .text
      } else {
        return ''
      }
    }),
    regenerate: Boolean(props.regenerate) || false
  })

  const fnUpdate = files => {
    state.files = files
  }

  const fnUpdateItemList = itemList => {
    state.itemList = itemList
  }

  const fnRemove = files => {
    state.files = files
  }

  const fnModalAddInfo = async () => {
    const payload = {
      component: proxy.$modalAlertNames.PARTY_ADD_INFO,
      data: {},
      callback: async () => {}
    }
    await fnModalAlert(payload)
  }

  const getMyPageMarketInfo = async () => {
    const res = await proxy.$MarketSvc.postMyPageMarketInfo(state.params)
    if (res.resultCode === '0000') {
      state.marketInfo = res.marketInfo
      if (state.regenerate) {
        state.itemList = []
        state.fileList = []
      } else {
        state.itemList = res.pinList
        state.fileList = res.file
      }
    } else {
      alert(res.resultMsg)
    }
  }

  const fnCategoryList = async () => {
    const res = await proxy.$MarketSvc.postMarketAddForm()
    if (res.resultCode === '0000') {
      state.category = res.category
      state.serverDt = res.serverDt
      state.sellerFee = res.sellerFee
    } else if (res.resultCode === '0001') {
      // data 없음
      state.category = [
        {
          categoryId: '2001',
          categoryNm: '상품권'
        },
        {
          categoryId: '2002',
          categoryNm: '티켓'
        }
      ]
    } else {
      alert(res.resultMsg)
    }
  }

  const isValid = async () => {
    const reqOptions = {
      reqKakao: state.marketInfo.reqKakao,
      reqPhone: state.marketInfo.reqPhone,
      reqEmail: state.marketInfo.reqEmail
    }
    if (proxy.$Util.isEmpty(state.marketInfo.title)) {
      alert('제목을 입력해주세요.')
      return false
    }
    if (
      state.marketInfo.unlimitedCheckYn === 'N' &&
      proxy.$Util.isEmpty(state.marketInfo.useDt)
    ) {
      alert('사용 기간을 설정해주세요.')
      return false
    }
    if (proxy.$Util.isEmpty(state.marketInfo.commonPrice)) {
      alert('개당 소비자 가격을 입력해주세요.')
      return false
    }
    if (proxy.$Util.isEmpty(state.marketInfo.price)) {
      alert('개당 판매 가격을 입력해주세요.')
      return false
    }
    if (Number(state.marketInfo.commonPrice) > 100000000) {
      alert('소비자가는 1억까지만 등록가능합니다.')
      return false
    }
    if (Number(state.marketInfo.price) > 100000000) {
      alert('판매가는 1억까지만 등록가능합니다.')
      return false
    }
    if (Number(state.marketInfo.price) < 1000) {
      alert('판매가는 1000원 이상으로 등록해주세요.')
      return false
    }
    if (proxy.$Util.isEmpty(state.marketInfo.contents)) {
      alert('상품 소개글을 입력해주세요.')
      return false
    }
    if (state.marketInfo.type == '0' && proxy.$Util.isEmpty(state.itemList)) {
      alert('핀번호를 입력해주세요.')
      return false
    }
    if (state.marketInfo.type == '1' && proxy.$Util.isEmpty(state.itemList)) {
      alert('상품 정보를 입력해주세요.')
      return false
    }
    if (
      state.marketInfo.type == '1' &&
      Object.keys(reqOptions).every(key => reqOptions[key] == 0)
    ) {
      alert('구매자 정보요청을 선택해주세요.')
      return false
    }
    return true
  }

  const fnSave = async () => {
    if (!(await isValid())) return
    if (!proxy.$Util.isEmpty(state.deleteFiles)) {
      await fnDeleteImage()
    }
    const request = {
      ...state.marketInfo,
      pinList: state.itemList,
      marketNo: state.params.marketNo
    }

    delete request.categoryId
    delete request.type

    const params = {
      request: JSON.stringify(request),
      file: state.files
    }

    const res = await proxy.$MarketSvc.postMyPageMarketInfoEdit(params)
    if (res.resultCode === '0000') {
      alert('상품이 수정되었습니다.')
      await router.push({ name: 'my-page-market-index', query: { index: 0 } })
    } else {
      alert(res.resultMsg)
      await router.go(-1)
    }
  }

  const fnRegenerate = async () => {
    if (!(await isValid())) return
    if (!proxy.$Util.isEmpty(state.deleteFiles)) {
      await fnDeleteImage()
    }
    const request = {
      ...state.marketInfo,
      pinList: state.itemList
    }

    let file = [...state.files]

    const params = {
      request: JSON.stringify(request),
      file
    }

    const res = await proxy.$MarketSvc.postMarketAdd(params)
    if (res.resultCode === '0000') {
      alert('상품이 재등록 되었습니다.')
      await router.push({ name: 'my-page-market-index', query: { index: 0 } })
    } else {
      alert(res.resultMsg)
      await router.go(-1)
    }
  }

  const fnDeleteFile = async deleteFileIds => {
    state.deleteFiles = deleteFileIds
  }

  const fnDeleteImage = async () => {
    const res = await proxy.$MarketSvc.postMyPageMarketImageDelete({
      fileId: state.deleteFiles,
      marketNo: state.params.marketNo
    })
    if (res.resultCode === '0000') {
      proxy.$emit('fetch')
    } else {
      alert(res.resultMsg)
    }
  }

  const maskingPriceNumber = e => {
    e.target.value = e.target.value.replace(/[e+-]/gi, '')
    if (e.target.value > 100000000) e.target.value = 100000000
    state.marketInfo.price = e.target.value
  }

  const maskingCommonPriceNumber = e => {
    e.target.value = e.target.value.replace(/[e+-]/gi, '')
    if (e.target.value > 100000000) e.target.value = 100000000
    state.marketInfo.commonPrice = e.target.value
  }

  const init = async () => {
    await fnCategoryList()
    await getMyPageMarketInfo()
  }

  init()

  return {
    ...toRefs(state),
    fnModalAddInfo,
    getMyPageMarketInfo,
    fnCategoryList,
    fnUpdate,
    fnRemove,
    fnSave,
    fnDeleteFile,
    maskingPriceNumber,
    maskingCommonPriceNumber,
    fnUpdateItemList,
    fnRegenerate
  }
}
