<template>
  <li class="form_li">
    <div class="form_ttl essen">상품 정보등록(최대 5개)</div>
    <div class="form_in">
      <label class="input_file">
        <button class="txtbutton" @click="fnModalAlertGoodsAdd">
          추가
        </button>
      </label>
      <div v-if="computedGoodsList.length > 0">
        <div
          class="inline"
          style="margin-top: 0.5rem"
          v-for="(item, index) in computedGoodsList"
          :key="index"
        >
          <label @click="fnModalAlertGoodsAdd(index, item)" class="input">
            <input
              class="clickable"
              :value="showUseDtText(item.useDt)"
              :class="{ disabled: item.memberSeq && item.memberSeq != 0 }"
              disabled
            />
          </label>
          <button
            class="btn_delete"
            v-if="!item.memberSeq || item.memberSeq == 0"
            @click="fnDeletePin(index)"
          >
            삭제
          </button>
        </div>
      </div>
      <div class="hint">
        동일한 제품만 복수 등록이 가능합니다.
      </div>
    </div>
  </li>
</template>

<script>
import { getCurrentInstance, reactive, toRefs, computed } from 'vue'
import { fnModalAlert } from '@/components/common/modal/alert'

const componentState = props => {
  const { proxy } = getCurrentInstance()
  const state = reactive({
    goodsList: [],
    computedGoodsList: computed(() => {
      if (props.editGoodsList) {
        state.goodsList = props.editGoodsList
        return state.goodsList
      } else {
        return state.goodsList
      }
    })
  })

  const fnModalAlertGoodsAdd = async (index, item) => {
    if (item && item.memberSeq && item.memberSeq != 0) return
    let data = {}
    if (item) {
      data = { ...item }
      data.isEdit = true
    } else {
      data.remainCount = props.maxCount - state.goodsList.length
    }
    if (proxy.$Util.isEmpty(item) && state.goodsList.length >= props.maxCount)
      return alert('최대 5개 까지 등록 가능합니다.')
    const payload = {
      component: proxy.$modalAlertNames.GOODS_ADD,
      data,
      callback: goodsList => {
        const copiedGoodsList = [...state.goodsList]
        if (item) {
          copiedGoodsList.splice(index, 1, goodsList[0])
          state.goodsList = copiedGoodsList
        } else {
          state.goodsList = [...state.goodsList, ...goodsList]
        }
        proxy.$emit('update', state.goodsList)
      }
    }

    await fnModalAlert(payload)
  }

  const fnDeletePin = index => {
    if (
      !confirm(
        '쿠폰번호 삭제 시 “추가”버튼을 통해 재등록을 진행해야 합니다.삭제 하시겠습니까?'
      )
    )
      return
    state.goodsList.splice(index, 1)
    proxy.$emit('update', state.goodsList)
  }

  const showUseDtText = useDt =>
    useDt
      ? `사용기한 ${proxy.$dayjs(useDt).format('YYYY-MM-DD')} 까지`
      : '기한 없음'

  return {
    ...toRefs(state),
    fnDeletePin,
    fnModalAlertGoodsAdd,
    showUseDtText
  }
}

export default {
  name: 'goods-add-list',
  props: {
    maxCount: {
      type: [Number],
      default: () => 5
    },
    editGoodsList: {
      type: [Array],
      default: undefined
    }
  },
  setup(props) {
    const state = componentState(props)
    return { ...state }
  }
}
</script>

<style scoped></style>
