<template>
  <li class="form_li">
    <div class="form_ttl">{{ buttonTitle }}</div>
    <div class="form_in">
      <label class="input_file">
        <input
          type="file"
          accept="image/gif, image/jpeg, image/png"
          @change="fnChangeSelectFile"
          onclick="this.value=null"
          :disabled="newItems.length >= dynamicMaxCnt"
        />
        <!--          onclick="this.value = ''"-->
        <span class="label bo">파일 선택</span>
      </label>
      <div
        class="input_file_name"
        v-for="(file, index) in copiedFiles"
        :key="index"
        style="margin-top: 0.5rem"
      >
        <div class="prof">
          <div class="img_area">
            <img
              :src="file.mImage"
              onError="this.style.visibility='hidden'"
              alt=""
              v-show="!$Util.isEmpty(file)"
            />
          </div>
        </div>
        <span>{{ file.mImage.split('/').reverse()[0] }}</span>
        <button class="btn_delete" @click="fnDeleteBindFile(file)">
          삭제
        </button>
      </div>
      <div v-if="files.length > 0">
        <div
          class="input_file_name"
          v-for="(file, index) in files"
          :key="index"
        >
          <div class="prof">
            <div class="img_area">
              <img
                :src="newItems[index]"
                onError="this.style.visibility='hidden'"
                alt=""
                v-show="!$Util.isEmpty(newItems[index])"
              />
            </div>
          </div>
          <span>{{ file.name }}</span>
          <button class="btn_delete" @click="fnDeleteFile(index)">삭제</button>
        </div>
      </div>
      <div class="hint">최대 {{ maxCount }}장까지 등록가능합니다.</div>
      <div class="hint">
        첨부파일은 3MB 미만의 용량으로 등록해주세요.
      </div>
    </div>
  </li>
</template>

<script>
import { getCurrentInstance, reactive, toRefs, watch, computed } from 'vue'
import { fnModal } from '@/components/common/modal'

const componentState = props => {
  const { proxy } = getCurrentInstance()
  const state = reactive({
    newItems: [],
    files: [],
    bindFilesLength: computed(() => state.copiedFiles.length),
    maxCnt: computed(() => props.maxCount),
    dynamicMaxCnt: computed(() => state.maxCnt - state.bindFilesLength),
    inputFile: '',
    deleteFileId: [],
    copiedFiles: computed(() => props.bindFiles)
  })

  const fnChangeSelectFile = $event => {
    if (typeof $event.target.files[0] === 'object') {
      const maxSize = 3 * 1024 * 1024
      const fileSize = $event.target.files[0].size
      if (fileSize > maxSize) {
        alert('첨부파일은 3MB 미만의 용량으로 등록해주세요.')
      } else {
        state.inputFile = $event.target.files[0]
      }
    } else {
      state.inputFile = ''
    }
  }

  const fnModalCropper = async (src, fileName) => {
    const payload = {
      component: proxy.$modalNames.CROPPER,
      data: {
        img: src,
        fileName: fileName,
        aspectRatio: props.aspectRatio
      },
      callback: (src, file) => {
        state.files.push(file)
        proxy.$emit('update', state.files)
        state.newItems.push(src)
      }
    }
    await fnModal(payload)
  }

  const fnDeleteBindFile = async ({ fileId }) => {
    const foundIndex = state.copiedFiles.findIndex(
      item => item.fileId === fileId
    )
    state.copiedFiles.splice(foundIndex, 1)
    state.deleteFileId.push(fileId)
    proxy.$emit('updateDeleteFile', state.deleteFileId)
  }

  const fnDeleteFile = index => {
    state.newItems.splice(index, 1)
    state.files.splice(index, 1)
    proxy.$emit('update', state.files)
  }

  watch(
    () => state.inputFile,
    val => {
      if (typeof val === 'object') {
        const reader = new FileReader()

        reader.onload = async event => {
          await fnModalCropper(event.target.result, val.name)
        }
        reader.readAsDataURL(val)
      }
    }
  )

  return {
    ...toRefs(state),
    fnDeleteBindFile,
    fnDeleteFile,
    fnChangeSelectFile
  }
}

export default {
  name: 'file-input-li',
  props: {
    maxCount: {
      type: [Number],
      default: () => 5
    },
    readOnly: {
      type: [Boolean],
      default: false
    },
    bindFiles: {
      type: [Array],
      default: () => []
    },
    value: {
      type: [Array, Object],
      default: () => []
    },
    marketNo: {
      type: [String, Number],
      default: () => ''
    },
    buttonTitle: {
      type: [String],
      default: () => '이미지 등록'
    },
    aspectRatio: {
      type: [Number, NaN],
      default: () => 16 / 9
    }
  },
  setup(props) {
    const state = componentState(props)
    return { ...state }
  }
}
</script>

<style scoped></style>
